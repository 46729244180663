<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>

  <!-- <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <div class="h3 fw-bold">The Royal Deco</div>
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
          </div>

          <a
            routerLink="/pages/contact"
            href="javascrip:void(0)"
            class="btn btn-solid m-0"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  </div> -->

  <div class="container">
    <div class="row align-items-center position-relative">
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <div class="logo">
          <img
            src="../../../../assets/images/logo.svg"
            alt=""
            class="h-100 w-100 royaldeco"
          />
        </div>
      </div>
      <div class="col-md-8 col-sm-8 col-lg-8 col-xl-8">
        <div class="nav d-block">
          <app-menu></app-menu>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 col-lg-2 button col-xl-2 text-end">
        <a
          routerLink="/pages/contact"
          href="javascrip:void(0)"
          class="btn btn-solid m-0"
        >
          Contact us
        </a>
      </div>
    </div>
  </div>
</header>
