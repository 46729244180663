import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from "@angular/core";
import { Product } from "../../classes/product";
import { ProductService } from "../../services/product.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit, OnChanges {
  @Input() selected;
  key = "";

  public products: Product[] = [];

  category = [
    {
      title: "new",
      value: "New",
    },
    {
      title: "Unique",
      value: "Unique",
    },
    {
      title: "In Offer",
      value: "Offers",
    },
    {
      title: "reduced  items",
      value: "Reduced items",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];
  public collapse: boolean = true;

  constructor(private fire: AngularFirestore) {
    // this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnChanges(change: any) {
    if (change?.selected?.currentValue != change?.selected?.previousValue) {
      this.selected = change?.selected?.currentValue;
      this.key = this.selected[Object.keys(this.selected)[0]];
    }
  }

  ngOnInit(): void {
    this.key = this.selected[Object.keys(this.selected)[0]];
  }
}
