import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-sizefilter",
  templateUrl: "./sizefilter.component.html",
  styleUrls: ["./sizefilter.component.scss"],
})
export class SizefilterComponent implements OnInit {
  @Input() selected;
  key = "";
  public collapse: boolean = true;

  sizes = [
    {
      title: "60x60",
      value: "60x60",
    },
    {
      title: "60x120",
      value: "60x120",
    },
    {
      title: "30x60",
      value: "30x60",
    },
    {
      title: "120x120",
      value: "120x120",
    },
    {
      title: "120x180",
      value: "120x180",
    },
    {
      title: "80x80",
      value: "80x80",
    },
    {
      title: "90x90",
      value: "90x90",
    },
    {
      title: "60x90",
      value: "60x90",
    },
    {
      title: "80x160",
      value: "80x160",
    },
    {
      title: "40x120",
      value: "40x120",
    },
    {
      title: "others",
      value: "others",
    },
  ];

  ngOnChanges(change: any) {
    if (change?.selected?.currentValue != change?.selected?.previousValue) {
      this.selected = change?.selected?.currentValue;
      this.key = this.selected[Object.keys(this.selected)[0]];
    }
  }

  ngOnInit(): void {
    this.key = this.selected[Object.keys(this.selected)[0]];
  }
}
