import { Component } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { log } from "console";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent {
  userEmail: any = "";
  public isVisible: boolean = false;

  constructor(private fire: AngularFirestore) {}

  ngOnInit() {
    setTimeout(() => {
      this.isVisible = true;
    }, 1000);
  }

  public closePopup() {
    this.isVisible = false;
  }

  onSendClick(userEmail) {
    const useremail = {
      email: this.userEmail,
    };

    this.fire
      .collection("Emaillist")
      .add(useremail)
      .then((docRef) => {
        this.userEmail = "";
        this.isVisible = false;
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
}
