<div class="collection-collapse-block border-0" [class.open]="!collapse">
  <h3 class="collapse-block-title" (click)="collapse = !collapse">Type</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list">
        <li *ngFor="let type of types">
          <a
            [class.active]="key == type.value"
            [routerLink]="['/shop/products']"
            [queryParams]="{ productType: type?.value }"
          >
            {{ type.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
