// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyB2R3TRQXpYhltOIz5JKuV-W0xk2gIYaZQ",
    authDomain: "web-eurotiles.firebaseapp.com",
    projectId: "web-eurotiles",
    storageBucket: "web-eurotiles.appspot.com",
    messagingSenderId: "56506000628",
    appId: "1:56506000628:web:0e2d233b43d03753f96aa1",
    measurementId: "G-MWJ0GEQHY2",
  },

  production: false,
  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
