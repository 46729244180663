<!--footer section -->
<footer [class]="class">
  <section class="section-b-space light-layout">
    <div class="container">
      <div class="row footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          <!-- <div class="footer-title footer-mobile-title">
            <h4>about</h4>
          </div> -->
          <div class="footer-contant">
            <!-- <img [src]="themeLogo" alt="logo" /> -->
            <div class="logo">
              <img
                src="../../../../assets/images/logo.svg"
                alt=""
                class="h-100 w-100"
              />
            </div>
            <p>
              Welcome to The Royal Deco, your one-stop destination for premium
              quality tiles and tile accessories. We specialize in providing a
              wide range of exquisite tiles that are both aesthetically pleasing
              and durable.
            </p>
            <div class="footer-social">
              <ul>
                <li>
                  <div class="fw-bold text-white pb-2">Follow Us :</div>

                  <a href="javascript:void(0) ">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/the_royal_deco?igsh=MXZqMmYzbGl1anMzZQ=="
                      class="px-2 text-white"
                    >
                      the_royal_deco</a
                    >
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col offset-xl-1">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Categories</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li *ngFor="let category of category">
                  <a
                    [routerLink]="['/shop/products']"
                    [queryParams]="{ launchCategory: category?.value }"
                  >
                    {{ category.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>visit</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    routerLink="home
                  "
                    >Home</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/shop/products"
                    >Products</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/services"
                    >Services</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/blog">Blog</a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/projects"
                    >Projects</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/aboutus"
                    >About Us</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/catalog"
                    >Catalogue
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/contact"
                    >Contact Us</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>store information</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li>
                  <i class="fa fa-map-marker"></i>The Royal Deco Demo Store,
                  <!-- Unit 278-287 station road Ha1 1na, london , United Kingdom -->
                  Unit c belvue house belvue road ub5 5QJ Northolt
                </li>
                <li>
                  <i class="fa fa-phone"></i>Call Us:
                  <br />
                  07414613813 , 07917646318
                </li>
                <li>
                  <i class="fa fa-envelope-o"></i>Email Us:
                  <a>info@theroyaldeco.co.uk</a>
                </li>
                <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              Copyright
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date : "y" }} The RoyalDeco | All Rights Reserved.
            </p>
          </div>
        </div>
        <div class="col-xl-6 text-end col-md-6 col-sm-12">
          <div class="text-secondary">
            Made with ❤️ by
            <a
              href="https://angriotechnologies.com"
              target="_blank"
              class="text-primary"
            >
              Angrio Technologies</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->
